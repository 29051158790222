.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 150px;
  left: 0;
  right: 0;
}

.disable-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black overlay */
  z-index: 99; /* ensure it's above other elements */
}

.render-hand-btn {
  color: #1877f2;
  border: 1px solid #1877f2;
  &:hover {
    border: 1px solid #1877f2 !important;
  }
  &:disabled & &:hover {
    border: 1px solid gray;
  }
}

.manga-text-box {
  font-family: 'arial';
  flex-wrap: wrap;
  border: 2px solid #7640cb;
  text-overflow: ellipsis;
  overflow: hidden;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
